import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const RewardOptions = styled.div`
margin: 20px 0;

.cell-heading {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cell-description {
  text-align: center;
  text-transform: uppercase;
}

.cell-image {
  display: inline-block;
  padding: 5px 10px;
  max-width: 350px;
  margin: auto;
}

.top-artist-grid {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.cell {
  padding: 0;
  margin: 0 0 40px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.black-label {
  margin: 80px auto 80px;
  padding: 30px;
  background-color: #000;
  max-width: 160px;
  height: auto;
  text-align: center;
  border-radius: 20px;
}

.black-label p {
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1.1em;
}

@media screen and (min-width: ${props => props.theme.responsive.medium}) {
  .top-artist-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .cell {
    padding: 10px;
    border-bottom: none;
  }

  .border-cell {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
  }

  .black-label {
    margin: 100px auto 0;
  }
}
`
const CustomList = styled.ul`
  line-height: 2em;
  margin: 0 auto 32px;
`

const CustomListItem = styled.li`
  &::before {
    content: '/';
    color: #000;
    margin-right: 5px;
  }
`

const PointSystem = styled.div`
  text-align: center;
  text-transform: uppercase;

  h4 {
    font-weight: 600;
  }

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin-top: 12px;
  }
`

const RewardDescription = styled.p`
  text-align: center;
  font-size: 16px;
  margin: 10px 0;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 20px 0;
  }
`

const RewardsLevelOne = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset(
        sort: { fields: [description], order: [ASC] },
        filter: { description: { regex: "/.*rewards-tier-one.*/" } }
      ) {
        edges {
          node {
            id
            title
            file {
              url
            }
          }
        }
      }
    }
  `)

  const rewardImages = data.allContentfulAsset.edges

  return (
    <>
      <h2 style={{ textTransform: 'uppercase', fontWeight: '600' }} className="headline--medium center-align">Rewards Level I</h2>
      <RewardDescription>Complete qualifications in these months and earn the following rewards!</RewardDescription>
      <RewardOptions>
        <div className="top-artist-grid">
          <div className="cell" key="key1">
            <h4 className="cell-heading">January - April</h4>
            <p className="cell-description">$150 Beauty Bonus</p>
            <div className="black-label">
              <p>
                Beauty<br />Bonus
              </p>
            </div>
          </div>
          <div className="cell border-cell" key="key2">
            <h4 className="cell-heading">May - August</h4>
            <p className="cell-description">{rewardImages[0].node.title}</p>
            <img className="cell-image" src={rewardImages[0].node.file.url} alt={rewardImages[0].node.title} />
          </div>
          <div className="cell" key="key3">
            <h4 className="cell-heading">September - December</h4>
            <p className="cell-description">{rewardImages[1].node.title}</p>
            <img className="cell-image" src={rewardImages[1].node.file.url} alt={rewardImages[1].node.title} />
          </div>
        </div>
      </RewardOptions>
      <h4 style={{ textTransform: 'uppercase', fontWeight: '600', marginTop: '0' }} className="center-align">How to Earn</h4>
      <CustomList>
        <CustomListItem>Total Points Needed - US: 5,500+ | CA: 7,500+</CustomListItem>
        <CustomListItem>Qualification Period - 4 months</CustomListItem>
        <CustomListItem># Months Required w/ Monthly Minimum - 3 months</CustomListItem>
        <CustomListItem>Monthly Minimum Requirement - US: 1,500 | CA: 2,000</CustomListItem>
      </CustomList>
      <PointSystem>
        <h4>Point System</h4>
        <p>
          1 Point = 1 SV<br />
          US: 500 Points | CA: 700 Points = New Artist who signs up and sells or purchases $50 USD | $70 CAD in the first month of sign up
        </p>
      </PointSystem>
    </>
  )
}

export default RewardsLevelOne
